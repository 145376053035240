.container {
    background-color: #282c34;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: white;
    text-align: center;
}

a {
    color: white;
    text-decoration: none; /* no underline */
}

h1,
h2 {
    font-size: 2rem;
    margin: 10px;
}

.recharts-wrapper {
    margin : 0 auto;
  }

.progress {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.progress-score{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
}

.progress span {
    font-size: 1rem;
}

.answers-cotainer{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    height: 100%;
}

.answer {
    border-radius: 10px;
    background-color: #ffffff;
    color: #282c34;
    text-align: center;
    margin: 5%;
    position: relative;
    font-size: 1rem;
    cursor: pointer;
    transition: ease-in-out, width 0.35s ease-in-out;
    width: 100%;
    height: 100%;
    min-height: 10vh;
}

.answer.selected {
    color: #4f4c4c;
    background-color: #c8ffbb;
}

.answer:hover {
    color: #4f4c4c;
    background-color: #c8ffbb;
}

.answer:focus {
    outline: none;
}

.results span.correct {
    color: #c8ffbb;
}

.results span.failed {
    color: #f27c7c;
}

.error {
    color: #f27c7c;
}

.DiscountContainer {
    border: solid ;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10%;
    max-width: 600px;
    background-color: #4f4c4c;
    width: 90%;
    border-radius: 10px;
    margin: 0% 2% 2% 2%;
    text-align: center;
}

.DiscountContainer h2 {
    font-size: 2rem;
    background-color: #000000;
    padding: 1%;
}

.DiscountContainer h3 {
    margin: 4%;
    text-align: center;
    font-size: 0.8rem;
}

.ResultCardContainer {
    border: solid ;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 10%;
    width: 90%;
    max-width: 600px;
    background-color: #4f4c4c;
    margin: 2%;
    border-radius: 10px;
}

.result-card {
    width: 50%;
    height: 20%;
    margin: 1%;
    align-items: center;
    border-radius: 10%;
    text-align: center;
}

.result-cardInner{
    text-align: center;
    width: 100%;
    max-width: 100%;
}

.result-cardInner h3 {
    font-size: 1rem;
    margin: 5%;
    text-align: center;
}

.result-card h4 {
    font-size: 0.6rem;
    margin: 5%;
}

.letter {
    color: #4f4c4c;
    width: 30px;
    position: absolute;
    left: 0;
    text-align: center;
    height: 28px;
    top: 0;
    padding: 5px;
    text-transform: uppercase;
}

.btn {
    color: #4f4c4c;
    background-color: #f4f4f4;
    padding: 10px 50px;
    text-transform: uppercase;
    font-size: 1rem;
    margin-top: 20px;
    cursor: pointer;
}

.btn-primary {
    width: 80%;
    color: #4f4c4c;
    background-color: #ffc107;
    font-weight: bold;
    max-width: 620px;
}

.quiz-img {
    height:200px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.starting-img {
    height: 30%;
    width: 90%;
    margin-top: 1%;
    margin-bottom: 2%;
    max-width: 600px;
    max-height: 250px;
}

.CombinationContainer {
    border: solid ;
    background-color: #4f4c4c;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    height: 10%;

}

.CombinationTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.CombinationTitle h2{
    margin: 2% 3% 0% 3%;
    flex:3;
    font-size: 1rem;
    text-align: start;
}

.CombinationTitle h3{
    margin: 2% 3% 0% 3%;
    flex:1;
    font-size: 0.8rem;
}

.combination-card {
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1%;
    background-color: #282c34;
    align-content: center;
    border-radius: 10px;
}

.combination-icon{
    margin: 0%;
    margin-top: 2%;
    flex:0.3;
    font-size: 3rem;
    text-align: center;
}

.combination-description {
    flex:1;
    margin-top: 3%;
    text-align: left;
}

.combination-premium{
    flex:0.2;
    text-align: center;
    vertical-align: middle;
}
.combination-card h1{
    font-weight: normal;
    vertical-align: middle;
    margin: 3%;
}

.combination-description h2{
    margin: 0%;
    font-weight: bold;
    font-size: 0.8rem;
}

.combination-description h3{
    margin: 0%;
    font-weight: normal;
    font-size: 0.6rem;

}

.starting-rule {
    border: solid ;
    background-color: #4f4c4c;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    height: 10%;
    text-align: left;
}

.starting-rule h2{
    font-size: 1.5rem;

}

.starting-rule p {
    margin: 10px;
    font-size: 1rem;
}